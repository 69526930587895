import { Col, Container, Row } from "react-bootstrap";

const About = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <h5>About the Dragonic Generator:</h5>
                    <p>Dragonic is an ancient language. Few nowadays know how to read it, but even a small knowledge of the language can allow the user to control reality itself.</p>
                    <p>Type your message in the text box and the resulting image will be that text in Dragonic.</p>
                    <p>NOTE: An infusion of Mana is required to fully activate the Dragonic. Most humans don't possess the ability to utilize Mana and so won't be able to use Dragonic to its full extent. This web tool does not provide Mana infusions anymore. Not after that unfortunate incident of which we are forbidden to speak. So don't ask us about it!</p>
                    <p>-TechyDad</p>
                </Col>
            </Row>
        </Container>
    )
}

export default About;