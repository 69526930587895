import { useRef } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";

import classes from './DragonicForm.module.css';

const DragonicForm = (props) => {
    const textToTranslate = useRef()
    
    const handleClick = () => {
        props.onTranslate(textToTranslate.current.value);
    }

    return (
        <Form className={classes.dragonicform}>
            <Container>
                <Row>
                    <Col>
                        <Form.Label>Text to Convert Into Dragonic (NOTE: For the Dragonic "ch" sound, use "]"):</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Stack direction="horizontal" gap={3}>
                            <Form.Control className="me-auto" type='text' ref={textToTranslate} />
                            <Button variant='primary' onClick={handleClick}>Convert</Button>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}

export default DragonicForm;