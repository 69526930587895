import classes from './Header.module.css';

const Header = (props) => {
    return (
        <div className={classes.header}>
            <div className={classes.maintitle}>
                <img src='images/dragon.png' alt='Dragon' width='32px' />
                &nbsp;Dragonic Generator&nbsp;
                <img src='images/dragon.png' alt='Dragon' width='32px' />
            </div>
        </div>
    )
}

export default Header;