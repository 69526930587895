import mergeImages from 'merge-images';
import { useState } from 'react';
// merge-images: https://www.npmjs.com/package/merge-images

const GenerateImage = (props) => {
    const LetterSize = 77;
    const LineSize = 80;

    const [textImage, setTextImage] = useState('');

    const AllowedLetters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 
        'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', ']', ' '];

    const StringToConvert = props.text.toLowerCase();
    const LetterArray = [...StringToConvert];
    const filteredLetterArray = LetterArray.filter(item => AllowedLetters.includes(item));

    const CharacterSeparationString = filteredLetterArray.toString().replace(/, /g, ' ').replace(/ ,/g, ' ');
    const CharacterSeparationArray = [...CharacterSeparationString];

    let LineNumber = 0;
    let LetterPosition = -1;
    let MaxLength = 0;
    const ImagesArray = CharacterSeparationArray.map((item) => {
        LetterPosition = LetterPosition + 1;
        if (LetterPosition > MaxLength) {
            MaxLength = LetterPosition;
        }
        if (item === ' ') {
            LineNumber = LineNumber + 1;
            LetterPosition = -1;
            return {
                src: '/images/dragonic/NewWord2.png', 
                x: LetterSize*LetterPosition, 
                y: LineSize*LineNumber
            };
        }
        if (item === ',') {
            return {
                src: '/images/dragonic/Separator.png', 
                x: LetterSize*LetterPosition, 
                y: LineSize*LineNumber
            };
        }
        if (item === ']') {
            return {
                src: '/images/dragonic/CH.png', 
                x: LetterSize*LetterPosition, 
                y: LineSize*LineNumber
            };
        }
        return {
            src: `/images/dragonic/${item.toUpperCase()}.png`, 
            x: LetterSize*LetterPosition, 
            y: LineSize*LineNumber
        };
    });

    console.log(LineNumber);
    console.log(ImagesArray);

    if (MaxLength > 0) {
        mergeImages(ImagesArray, {
            width: LetterSize*(MaxLength+1),
            height: LineSize*(LineNumber + 1),
        }).then(b64 => setTextImage(b64));
    } else {
        if (textImage !== '') {
            setTextImage('');
        }
    }

    return (
        <>
            {textImage !== '' && <img src={textImage} key='abcd1234' alt='Dragonic Text' /> }
            {textImage === '' && <p>Please enter some text above to convert.</p>}
        </>
    );
}

export default GenerateImage;