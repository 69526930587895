import { Col, Container, Row } from "react-bootstrap";

const Credits = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <h5>Credits:</h5>
                    <p>
                    The dragon image above is from <a href='https://www.flaticon.com/packs/fairytale-33' target='_blank' rel='noreferrer'>Freepix' free Fairytale pack</a>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Credits;