import { useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import About from "./About";
import Credits from "./Credits";
import DragonicForm from "./DragonicForm";
import GenerateImage from "./GenerateImage";
import Header from "./Header";

import classes from './MainPage.module.css';

const MainPage = () => {
    const [textForTranslation, setTextForTranslation] = useState('');
    const translateText = (newText) => {
        setTextForTranslation(newText);
    }
    return (
        <div>
            <Header />
            <Tabs defaultActiveKey='generator' id='generatortabs'>
                <Tab eventKey='generator' title='Dragonic Generator' className={classes.tab}>
                    <DragonicForm onTranslate={translateText} />
                    <Container>
                        <Row>
                            <Col>
                                <GenerateImage text={textForTranslation} />
                            </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey='about' title='About' className={classes.tab}>
                    <About />
                </Tab>
                <Tab eventKey='credits' title='Credits' className={classes.tab}>
                    <Credits />
                </Tab>

            </Tabs>
        </div>
    )
}

export default MainPage;